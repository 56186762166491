import { useState, useEffect } from 'react';
import {
    BlockchainApiController,
    NetworkController,
    BlockchainApiBalanceResponse,
    CoreHelperUtil,
    AccountController,
    BlockchainApiTransactionsResponse
} from '@web3modal/core';
import { chains, projectId } from '../providers/ProviderWeb3Modal';

// Custom hook for fetching token balance
export const useUserAllBalances = (address: string | undefined) => {
    const [tokens, setTokens] = useState<BlockchainApiBalanceResponse | null>(
        null
    );
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!address) return; // Run only if address is defined

        const fetchTokenBalance = async () => {
            setLoading(true);
            setError(null);
            try {
                const tokens = await BlockchainApiController?.getBalance(
                    `${address}`,
                    NetworkController?.state?.caipNetwork?.id
                );
                setTokens(tokens);
            } catch (err) {
                // @ts-ignore
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchTokenBalance(); // Initial fetch

        const intervalId = setInterval(fetchTokenBalance, 5000); // Fetch every 5 seconds

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [address]);

    return { tokens, loading, error };
};

export const useUserAllTransactions = (address: string | undefined) => {
    const [transactions, setTransactions] =
        useState<BlockchainApiTransactionsResponse | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!address) return; // Run only if address is defined

        const fetchTransaction = async () => {
            setLoading(true);
            setError(null);
            try {
                const transactions =
                    await BlockchainApiController?.fetchTransactions({
                        account: `${address}`,
                        projectId: projectId
                    });

                setTransactions(transactions);
            } catch (err) {
                // @ts-ignore
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchTransaction();
    }, [address]);

    return { transactions, loading, error };
};

import { useAccount } from "wagmi";
import { useUserAllBalances } from "../hooks/wcHooks";
import { CoreHelperUtil } from "@web3modal/core";
import { Balance, NumberUtil } from "@web3modal/common";
import {
  Avatar,
  Flex,
  Heading,
  HStack,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconType } from "react-icons";
import { FcBarChart } from "react-icons/fc";
import DashboardItemContainer from "./DashboardItemContainer";

export const UserAllBalancesComponent = () => {
  const { address } = useAccount();
  const balances = useUserAllBalances(address);
  const totalBalanceUSD = CoreHelperUtil?.calculateBalance(
    balances?.tokens?.balances ?? []
  );

  const BalanceContainer = ({ balance }: { balance: Balance }) => {
    return (
      <HStack w="full">
        <Avatar
          src={balance?.iconUrl}
          name={balance?.symbol}
          size="xs"
        ></Avatar>
        <Flex direction="column">
          <Heading size="xs">{balance?.name}</Heading>
          <Text fontSize="xs">{balance?.symbol}</Text>
        </Flex>
        <Spacer />
        <Flex direction="column" textAlign="right">
          <Heading size="xs">
            {NumberUtil?.formatNumberToLocalString(
              balance?.quantity?.numeric,
              4
            )}
          </Heading>
          <Text fontSize="xs">
            ${NumberUtil?.formatNumberToLocalString(balance?.value)} USD
          </Text>
        </Flex>
      </HStack>
    );
  };

  return (
    <DashboardItemContainer heading="Balances" style={{
      w: "full"
    }}>
      <VStack spacing={0} w="full">
        <Heading size="md">
          ${NumberUtil?.formatNumberToLocalString(totalBalanceUSD, 4)} USD
        </Heading>
        <Text fontStyle="italic" fontWeight={200}>
          Total Balance
        </Text>
      </VStack>
      {
        balances?.tokens?.balances?.map((balance, key) => {
          return <BalanceContainer balance={balance} key={key} />;
        })
      }
    </DashboardItemContainer >
  );
};

import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  HStack,
  Icon,
  Spacer,
  Tag,
  Text,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import {
  FcConferenceCall,
  FcDoughnutChart,
  FcElectricalSensor,
  FcFeedIn,
} from 'react-icons/fc';
import { HiUser, HiUserGroup, HiUsers } from 'react-icons/hi2';
import { LuPalmtree } from 'react-icons/lu';
import { RiRobot2Fill } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import { formatEther } from 'viem';
import { useAccount } from 'wagmi';
import DashboardItemContainer from '../../../components/DashboardItemContainer';
import { UserAllBalancesComponent } from '../../../components/UserAllBalancesComponent';
import {
  useGetContractAnalytics,
  useGetUserAccount,
  UseReadContractWithUserAccountType,
} from '../../../hooks/referralHooks';
import { ReferralLinkComponent } from '../team/TeamDashboard';

// export const MaxLimitComponent = ({
//   userAccount,
// }: {
//   userAccount: UseReadContractWithUserAccountType;
// }) => {
//   const maxLimit = userAccount?.data?.limits?.maxLimit ?? BigInt(0);
//   const currentLimit = userAccount?.data?.limits?.currentLimit ?? BigInt(0);
//   const remainingLimit = maxLimit - currentLimit;

//   const limitReachPer =
//     Number(maxLimit) > 0
//       ? Number(
//         ((Number(currentLimit) * 100) / Number(maxLimit))?.toFixed(0),
//       )
//       : 0;

//   function getColorForPercentage(percentage: number): string {
//     if (percentage <= 25) {
//       // 0% to 50% - Green
//       return 'green';
//     } else if (percentage > 25 && percentage <= 50) {
//       // 51% to 99% - Yellow
//       return 'orange';
//     } else if (percentage > 50 && percentage <= 75) {
//       // 100% - Red
//       return 'yellow';
//     } else {
//       return 'red';
//     }
//   }

//   return (
//     <DashboardItemContainer heading="Max Limits" icon={FcDoughnutChart}>
//       <CircularProgress
//         value={limitReachPer}
//         size="120px"
//         color={getColorForPercentage(limitReachPer)}
//       >
//         <CircularProgressLabel
//           color={getColorForPercentage(limitReachPer)}
//         >
//           {Number(limitReachPer)}%
//         </CircularProgressLabel>
//       </CircularProgress>
//       <VStack w="full">
//         <Text fontWeight={900}>Reward Collected</Text>
//         <Text>
//           $
//           {formatEther(
//             userAccount?.data?.limits?.currentLimit ?? BigInt(0),
//           )}{' '}
//           USD
//         </Text>
//       </VStack>
//       <VStack w="full">
//         <Text fontWeight={900}>Max Rewards</Text>
//         <Text>
//           $
//           {formatEther(
//             userAccount?.data?.limits?.maxLimit ?? BigInt(0),
//           )}{' '}
//           USD
//         </Text>
//       </VStack>
//       <VStack w="full">
//         <Text fontWeight={900}>Remaining Rewards</Text>
//         <Text>${formatEther(remainingLimit ?? BigInt(0))} USD</Text>
//       </VStack>
//       <Button as={Link} to="/register">
//         Increase Limit
//       </Button>
//     </DashboardItemContainer>
//   );
// };

export const BalancesComponent = () => {
  return <UserAllBalancesComponent />;
};

export const BusinessComponent = ({
  userAccount,
}: {
  userAccount: UseReadContractWithUserAccountType;
}) => {
  const dataArray = [
    {
      label: 'Total Deposits',
      valueInUSD: formatEther(
        userAccount?.data?.business?.selfBusiness ?? BigInt(0),
      ),
      icon: HiUser,
    },
    {
      label: 'Direct Team Deposits',
      valueInUSD: formatEther(
        userAccount?.data?.business?.directBusiness ?? BigInt(0),
      ),
      icon: HiUsers,
    },
    {
      label: 'Total Team Deposits',
      valueInUSD: formatEther(
        userAccount?.data?.business?.teamBusiness ?? BigInt(0),
      ),
      icon: HiUserGroup,
    },
  ];
  return (
    <DashboardItemContainer heading="Business" icon={FcFeedIn} style={{
      w: "full",
      maxW: 400
    }}>
      {dataArray?.map((datasObject, key) => {
        return (
          <Tag as={Flex} w="full" borderWidth="thin" p={5} borderRadius="3xl" colorScheme="twitter">
            <Flex direction="column" gap={2}>
              <Heading size="sm">{datasObject?.label}</Heading>
              <Text>$ {datasObject?.valueInUSD} USD</Text>
            </Flex>
            <Spacer />
            <Icon as={datasObject?.icon} boxSize={8}></Icon>
          </Tag>
        );
      })}
    </DashboardItemContainer>
  );
};

export const TeamComponent = ({
  userAccount,
}: {
  userAccount: UseReadContractWithUserAccountType;
}) => {
  const dataArray = [
    {
      label: 'Direct Team',
      value: Number(userAccount?.data?.referees?.length ?? 0),
      icon: HiUsers,
    },
    {
      label: 'Total Team',
      value: Number(userAccount?.data?.teams?.length ?? 0),
      icon: HiUserGroup,
    },
  ];
  return (
    <DashboardItemContainer heading="Teams" icon={FcConferenceCall}>
      {dataArray?.map((datasObject, key) => {
        return (
          <Tag as={Flex} w="full" borderWidth="thin" p={5} borderRadius="3xl" colorScheme="twitter">
            <Flex direction="column" gap={2}>
              <Heading size="sm">{datasObject?.label}</Heading>
              <Text>{datasObject?.value} Users</Text>
            </Flex>
            <Spacer />
            <Icon as={datasObject?.icon} boxSize={8}></Icon>
          </Tag>
        );
      })}
    </DashboardItemContainer>
  );
};

export const IncomeComponent = ({
  userAccount,
}: {
  userAccount: UseReadContractWithUserAccountType;
}) => {
  const dataArray = [
    {
      label: 'ROI Income',
      valueInUSD: 0,
      icon: HiUserGroup,
    },
    {
      label: 'Steps Income',
      valueInUSD: formatEther(
        userAccount?.data?.incomes?.referral ?? BigInt(0),
      ),
      icon: HiUserGroup,
    },
    {
      label: 'Bot Income',
      valueInUSD: formatEther(
        userAccount?.data?.incomes?.bot ?? BigInt(0),
      ),
      icon: RiRobot2Fill,
    },
    {
      label: 'Team Bonus',
      valueInUSD: formatEther(
        userAccount?.data?.incomes?.community ?? BigInt(0),
      ),
      icon: LuPalmtree,
    },
    {
      label: 'Rewards',
      valueInUSD: formatEther(
        userAccount?.data?.incomes?.bonanza ?? BigInt(0),
      ),
      icon: LuPalmtree,
    },
  ];
  return (
    <DashboardItemContainer heading="Rewards" icon={FcConferenceCall}>
      {dataArray?.map((datasObject, key) => {
        return (
          <Tag as={Flex} w="full" borderWidth="thin" p={5} borderRadius="3xl" colorScheme="green">
            <Flex direction="column" gap={2}>
              <Heading size="sm">{datasObject?.label}</Heading>
              <Text>$ {datasObject?.valueInUSD} USD</Text>
            </Flex>
            <Spacer />
            <Icon as={datasObject?.icon} boxSize={8}></Icon>
          </Tag>
        );
      })}
    </DashboardItemContainer>
  );
};

export const AllStats = () => {

  const { address } = useAccount();
  const { userAddress } = useParams<{
    userAddress: `0x${string} ` | undefined;
  }>();

  const currentUser = userAddress ?? address;
  const userAccount = useGetUserAccount(currentUser);
  const analyticsAndDefaults = useGetContractAnalytics();

  console.log('analyticsAndDefaults', analyticsAndDefaults);
  console.log('userAccount', userAccount);

  return (
    <VStack spacing={10} w={["full"]}>
      <HStack>
        <Heading>Dashboard</Heading>
        <Icon as={FcElectricalSensor} boxSize={16}></Icon>
      </HStack>
      <Wrap justify="center">
        {/* <MaxLimitComponent userAccount={userAccount} /> */}
        <BalancesComponent />
        <BusinessComponent userAccount={userAccount} />
        <TeamComponent userAccount={userAccount} />
        <IncomeComponent userAccount={userAccount} />
      </Wrap>
      <ReferralLinkComponent address={userAddress} />
    </VStack>
  );
};

import { ReactNode } from "react";
import { useAccount, useChainId } from "wagmi";
import { ErrorWalletNotConnectedComponent } from "../pages/error/ErrorWalletNotConnectedComponent";
import { PublicStateController } from "@web3modal/core";

const ProtectNoWalletLogin = ({ children }: { children: ReactNode }) => {
  const { address } = useAccount();
  return <>{!address ? <ErrorWalletNotConnectedComponent /> : children}</>;
};

const ProtectUnSupportedNetwork = ({ children }: { children: ReactNode }) => {
  const chainId = useChainId();
  return <>{children}</>;
};

const ProtectUserNotActive = ({ children }: { children: ReactNode }) => { };

const Protect404 = () => { };

export const ProtectedRoutes = ({ children }: { children: ReactNode }) => {
  return (
    <ProtectNoWalletLogin>
      <ProtectUnSupportedNetwork>{children}</ProtectUnSupportedNetwork>
    </ProtectNoWalletLogin>
  );
};

import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { HStack, Icon, Link, Tooltip, useClipboard } from '@chakra-ui/react';
import { CheckIcon } from 'lucide-react';
import { MdOutlineDashboard } from 'react-icons/md';
import { useChains } from 'wagmi';

export const AddressActionButtons = ({
  address,
}: {
  address: `0x${string}`;
}) => {
  const { hasCopied, onCopy } = useClipboard(address!);
  const chains = useChains();
  const blockExplorerUrl = `${chains?.[0]?.blockExplorers?.default?.url}/address/${address}`;

  return (
    <HStack>
      <Tooltip label={'Copy Address'} borderRadius="xl">
        <Icon
          as={hasCopied ? CheckIcon : CopyIcon}
          boxSize={5}
          cursor={'pointer'}
          onClick={onCopy}
        ></Icon>
      </Tooltip>
      <Tooltip label={'Open in block explorer'} borderRadius="xl">
        <Link href={blockExplorerUrl} target="_blank">
          <Icon
            as={ExternalLinkIcon}
            boxSize={5}
            cursor={'pointer'}
          ></Icon>
        </Link>
      </Tooltip>
      <Tooltip label={'Go to user dashboard'} borderRadius="xl">
        <Icon
          as={MdOutlineDashboard}
          boxSize={5}
          cursor={'pointer'}
        ></Icon>
      </Tooltip>
    </HStack>
  );
};

import { useChainId, useReadContract, UseReadContractReturnType } from 'wagmi';
import { zeroAddress } from 'viem';
import {
    StructUserAccountReturnStructOutput,
    StructReturnsAnalyticsAndDefaultsStructOutput
} from '../web3/contracts/typechain-types/contracts/Interfaces.sol/IStakingContract';
// @ts-ignore
import { StructStakingStructOutput } from '../web3/contracts/typechain-types/contracts/IfasstUpgradeable';
import { IfasstStakingUpgradeableABI, IfasstStakingUpgradeableDeploymentDetails } from '../constants/contractDeploymentDetails/IfasstStakingUpgradeable';

export const useReadStakingContract = (
    functionName:
        "owner" | "UPGRADE_INTERFACE_VERSION" | "proxiableUUID" | "getUserStakingsReward" | "getStakingAccountById" | "getStakingRewardById" | "getStakingUserAccount" | "getStakingContractAnalytics" | "getVariablesContract" | "paused" | undefined,
    args:
        readonly [] | readonly [`0x${string}`] | readonly [bigint] | undefined
) => {
    const chainId = useChainId();
    const contractObject = IfasstStakingUpgradeableDeploymentDetails[chainId];

    // @ts-ignore
    const result = useReadContract({
        abi: IfasstStakingUpgradeableABI,
        address: contractObject?.proxyAddress as `0x${string}`,
        functionName: functionName,
        args: args,
        blockTag: 'latest'
    });

    return result;
};

export type UseReadContractWithUserAccountType = Omit<
    UseReadContractReturnType,
    'data'
> & {
    data: StructUserAccountReturnStructOutput | undefined;
};

export const useGetStakingUserAccount = (address: `0x${string}` | undefined) => {
    address = address ?? zeroAddress;
    const result = useReadStakingContract('getStakingUserAccount', [
        address
    ]) as UseReadContractWithUserAccountType;

    return result;
};

export const useGetStakingContractAnalytics = () => {
    let result = useReadStakingContract('getStakingContractAnalytics', []);
    const resultWithType = {
        ...result,
        data: result?.data
    } as
        | (typeof result & {
            data: StructReturnsAnalyticsAndDefaultsStructOutput;
        })
        | undefined;

    console.log('getContractAnalytics from contract.', resultWithType);

    return resultWithType;
};

export const useGetUserPendingStakingRewards = (
    address: `0x${string}` | undefined
) => {
    let result = useReadStakingContract('getUserStakingsReward', [
        address ?? zeroAddress
    ]);

    const resultWithType = {
        ...result,
        data: result?.data
    } as
        | (typeof result & {
            data: bigint;
        })
        | undefined;

    return resultWithType;
};

export const useGetStakingById = (stakingId: bigint) => {
    let result = useReadStakingContract('getStakingAccountById', [
        stakingId
    ]);

    const resultWithType = {
        ...result,
        data: result?.data
    } as
        | (typeof result & {
            data: [StructStakingStructOutput, bigint] & {
                stakingAccount: StructStakingStructOutput;
                pendingReward: bigint;
            };
        })
        | undefined;

    return resultWithType;
};

import {
  Button,
  Center,
  Divider,
  Heading,
  HStack,
  Icon,
  Input,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
  useColorModeValue,
  VStack,
  Wrap
} from '@chakra-ui/react';
import { CheckIcon, ChevronDown, ShoppingCart } from 'lucide-react';
import { IconType } from 'react-icons';
import { BiCopy } from 'react-icons/bi';
import { GiTakeMyMoney } from 'react-icons/gi';
import {
  HiMiniUser,
  HiMiniUserGroup,
  HiMiniUserPlus,
  HiMiniUsers,
  HiUserGroup,
  HiUsers
} from 'react-icons/hi2';
import { RiTeamFill, RiUser6Fill, RiUserFill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { formatEther, zeroAddress } from 'viem';
import { useAccount } from 'wagmi';
import { StructTeamStructOutput } from '../../../../web3/contracts/typechain-types/contracts/IfasstUpgradeable';
import { AddressActionButtons } from '../../../components/AddressActionButtons';
import { useGetUserAccount } from '../../../hooks/referralHooks';
import { shortenAddress } from '../../../utils/web3Functions';
import { TeamComponent } from '../allstats/AllStats';

export const ReferralLinkComponent = ({ address }: { address: `0x${string}` | undefined }) => {
  const referrerAddress = `${window?.location?.origin}/#/register/${address}`;
  const { hasCopied, onCopy } = useClipboard(referrerAddress);
  return <VStack>
    <Text>Share your referral link to get rewards.</Text>
    <Input
      placeholder="Your referral link"
      textAlign="center"
      value={referrerAddress}
      fontSize="xs"
      bgColor={useColorModeValue('white', 'gray.900')}
      isReadOnly
    ></Input>
    <Button
      onClick={onCopy}
      rightIcon={hasCopied ? <CheckIcon /> : <BiCopy />}
      colorScheme={hasCopied ? 'green' : 'pink'}
    >
      {hasCopied
        ? 'Copied Successfully'
        : 'Copy referral link'}
    </Button>
  </VStack>
}

const UserCard = ({
  address,
  icon,
  label
}: {
  address: `0x${string}` | undefined;
  icon: IconType;
  label: string;
}) => {
  const userAccount = useGetUserAccount(address);

  const data = [
    {
      label: 'Self Business',
      value: formatEther(
        userAccount?.data?.business?.selfBusiness ?? BigInt(0)
      ),
      postText: 'USD',
      icon: [HiMiniUser, ShoppingCart]
    },
    {
      label: 'Direct Business',
      value: formatEther(
        userAccount?.data?.business?.directBusiness ?? BigInt(0)
      ),
      postText: 'USD',
      icon: [HiMiniUsers, ShoppingCart]
    },
    {
      label: 'Team Business',
      value: formatEther(
        userAccount?.data?.business?.teamBusiness ?? BigInt(0)
      ),
      postText: 'USD',
      icon: [HiMiniUserGroup, ShoppingCart]
    },
    {
      label: 'Referees',
      value: userAccount?.data?.referees?.length ?? 0,
      postText: 'Users',
      icon: [HiMiniUserPlus, HiUsers]
    },
    {
      label: 'Team',
      value: userAccount?.data?.teams?.length ?? 0,
      postText: 'Users',
      icon: [HiMiniUserPlus, HiUserGroup]
    }
    // {
    //   label: 'Total Income',
    //   value: formatEther(
    //     userAccount?.data?.incomes?.totalIncomeDistributed ?? BigInt(0),
    //   ),
    //   postText: 'USD',
    //   icon: [GiTakeMyMoney],
    // },
  ];

  return (
    <VStack>
      <Heading size="md">{label}</Heading>
      <Center
        p={2}
        w={200}
        minH={275}
        borderWidth={'thin'}
        borderRadius="3xl"
        flexDirection="column"
        gap={2}
        bgColor={useColorModeValue('white', 'gray.900')}
      >
        <Icon as={icon} boxSize={14}></Icon>
        <Tag borderRadius="full">
          <Text fontSize="xs">{shortenAddress(address)}</Text>
        </Tag>
        <AddressActionButtons address={address!} />
        <Wrap align="center" justify="center">
          {data?.map((object, key) => {
            return (
              <Tooltip
                label={object?.label}
                borderRadius="xl"
                key={key}
              >
                <Tag
                  flexDirection="column"
                  p={2}
                  gap={1}
                  colorScheme="green"
                  borderRadius="xl"
                >
                  <HStack spacing={0}>
                    {object?.icon?.map((icon, key) => {
                      return (
                        <Icon
                          as={icon}
                          key={key}
                        ></Icon>
                      );
                    })}
                  </HStack>

                  <Text fontSize="xs">
                    {object?.value} {object?.postText}
                  </Text>
                </Tag>
              </Tooltip>
            );
          })}
        </Wrap>
      </Center>
    </VStack>
  );
};

const RefereeCardComponent = ({
  address
}: {
  address: `0x${string}` | undefined;
}) => {
  const userAccount = useGetUserAccount(address);

  return (
    <VStack>
      <VStack
        p={5}
        w={150}
        borderWidth={'thin'}
        borderRadius="3xl"
        bgColor={useColorModeValue('white', 'gray.900')}
      >
        <Icon as={HiMiniUsers} boxSize={7}></Icon>
        <Tag borderRadius="full">
          <Text fontSize="xs">
            {shortenAddress(
              userAccount?.data?.userAddress as `0x${string}`
            )}
          </Text>
        </Tag>
        <AddressActionButtons address={address!} />
      </VStack>
    </VStack>
  );
};

const TeamTableComponent = ({
  teamArray
}: {
  teamArray: StructTeamStructOutput[] | undefined;
}) => {
  const bgColor = useColorModeValue('white', 'gray.900');
  return (
    <VStack w="full">
      <Heading size="md">Teams</Heading>
      {teamArray && teamArray?.length > 0 ? (
        <TableContainer
          bgColor={bgColor}
          borderRadius="3xl"
          py={5}
          w="full"
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Level</Th>
                <Th>Address</Th>
              </Tr>
            </Thead>
            <Tbody>
              {teamArray?.map((teamMemberDetails, key) => {
                return (
                  <Tr key={key}>
                    <Td>
                      {Number(teamMemberDetails?.level)}
                    </Td>
                    <Td gap={5} display="flex">
                      <Tag borderRadius="full">
                        {shortenAddress(
                          teamMemberDetails?.user as `0x${string}`
                        )}
                      </Tag>
                      <AddressActionButtons
                        address={
                          teamMemberDetails?.user as `0x${string}`
                        }
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Heading size="md" color="red.400">
          You have no team
        </Heading>
      )}
    </VStack>
  );
};

const TeamDashboard = () => {
  const { userAddress } = useParams<{
    userAddress: `0x${string}` | undefined;
  }>();
  const { address } = useAccount();
  const currentAddress = userAddress ?? address;
  const userAccount = useGetUserAccount(currentAddress);

  return (
    <VStack w="full" spacing={10}>
      <HStack>
        <Heading>Teams</Heading>
        <Icon as={RiTeamFill} boxSize={10}></Icon>
      </HStack>
      <TeamComponent userAccount={userAccount} />
      <ReferralLinkComponent address={currentAddress} />
      <Divider />
      <VStack w="full">
        {userAccount?.data?.referrer !== zeroAddress && (
          <VStack>
            <UserCard
              address={
                userAccount?.data?.referrer as
                | `0x${string}`
                | undefined
              }
              icon={RiUser6Fill}
              label="Referrer"
            />
            <Icon as={ChevronDown}></Icon>
          </VStack>
        )}

        <VStack>
          <UserCard
            icon={RiUserFill}
            address={currentAddress}
            label="You"
          />
        </VStack>
        <VStack>
          <Icon as={ChevronDown}></Icon>
          <Heading size="md">Referees</Heading>
          <Wrap w="full" justify="center" align="center">
            {userAccount?.data &&
              userAccount?.data?.referees?.length > 0 ? (
              userAccount?.data?.referees?.map((address, key) => {
                return (
                  <RefereeCardComponent
                    address={address as `0x${string}`}
                    key={key}
                  />
                );
              })
            ) : (
              <Heading size="md" color="red.400">
                You have no referee
              </Heading>
            )}
          </Wrap>
        </VStack>
      </VStack>
      <Divider color="pink.400" borderColor="pink.400" />
      <TeamTableComponent teamArray={userAccount?.data?.teams} />
    </VStack>
  );
};

export default TeamDashboard;

import { Center, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { WalletConnectButton } from "../../components/WalletConnectButton";
import { UserRoundX } from "lucide-react";

export const ErrorWalletNotConnectedComponent = () => {
  return (
    <Center minH={"70vh"}>
      <VStack>
        <UserRoundX size={100} />
        <VStack>
          <Heading color="red.400">You are not login!</Heading>
          <Text>Please login to continue</Text>
        </VStack>
        <WalletConnectButton />
      </VStack>
    </Center>
  );
};

import { Heading, Icon, StackProps, useColorModeValue, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';

export const DashboardItemContainer = ({
    children,
    heading,
    icon,
    style
}: {
    children: ReactNode;
    heading?: string;
    icon?: IconType;
    style?: StackProps
}) => {
    return (
        <VStack
            p={5}
            spacing={5}
            borderWidth={'thin'}
            borderRadius="3xl"
            // bgColor={useColorModeValue('white', 'gray.900')}
            minW={['full', 250]}
            {...style}
        >
            {heading && <Heading size="md">{heading}</Heading>}
            {icon && <Icon as={icon} boxSize={20}></Icon>}
            {children}
        </VStack>
    );
};

export default DashboardItemContainer;


        // Updated on 2024-09-15T20:43:35.720Z
        import { ContractDeploymentObjectsBlockchain } from '../types';
        export const IfasstUpgradeableABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "target",
        "type": "address"
      }
    ],
    "name": "AddressEmptyCode",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "implementation",
        "type": "address"
      }
    ],
    "name": "ERC1967InvalidImplementation",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ERC1967NonPayable",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "FailedInnerCall",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidInitialization",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidUserAddress",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvestmentPlanNotActive",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotInitializing",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "OwnableInvalidOwner",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "OwnableUnauthorizedAccount",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ReentrancyGuardReentrantCall",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "RefereeCannotBeReferrerUpline",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ReferrerCantBeSame",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "UUPSUnauthorizedCallContext",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "slot",
        "type": "bytes32"
      }
    ],
    "name": "UUPSUnsupportedProxiableUUID",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ValueLessThanMinContributionUSD",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "parent",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "referee",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "DirectBusinessUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint64",
        "name": "version",
        "type": "uint64"
      }
    ],
    "name": "Initialized",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "planId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "per",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "duration",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "maxLimitMul",
            "type": "uint256"
          }
        ],
        "indexed": false,
        "internalType": "struct StructInvestmentPlan[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "name": "InvestmentPlansUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferStarted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "provider",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      }
    ],
    "name": "ProviderValuePaid",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "userAddress",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "referrerAddress",
        "type": "address"
      }
    ],
    "name": "ReferrerAdded",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "userAddress",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "valueInWei",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "valueInUSD",
        "type": "uint256"
      }
    ],
    "name": "Registration",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "bool",
        "name": "status",
        "type": "bool"
      }
    ],
    "name": "SupportedTokenStatus",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "parent",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "referee",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "TeamBusinessUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "upline",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "teamMemberAddress",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "level",
        "type": "uint256"
      }
    ],
    "name": "TeamMemberAdded",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "implementation",
        "type": "address"
      }
    ],
    "name": "Upgraded",
    "type": "event"
  },
  {
    "stateMutability": "payable",
    "type": "fallback"
  },
  {
    "inputs": [],
    "name": "UPGRADE_INTERFACE_VERSION",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "acceptOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "token_",
        "type": "address[]"
      },
      {
        "internalType": "bool[]",
        "name": "isNative_",
        "type": "bool[]"
      },
      {
        "internalType": "address[]",
        "name": "aggregator_",
        "type": "address[]"
      }
    ],
    "name": "addSupportedToken",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getContractAnalytics",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address[]",
            "name": "users",
            "type": "address[]"
          },
          {
            "internalType": "contract IVariables",
            "name": "variablesInterface",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "stakingIds",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "totalBusiness",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "minContribution",
            "type": "uint256"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "division",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructPerAndDiv",
                "name": "referralRate",
                "type": "tuple"
              },
              {
                "internalType": "uint256",
                "name": "levelCondition",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructReferralRates[]",
            "name": "referralRates",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "referral",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bot",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "staking",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "community",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bonanza",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "target",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "others",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructIncome",
            "name": "incomeDistributed",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "isNative",
                "type": "bool"
              },
              {
                "internalType": "address",
                "name": "contractAddress",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "chainLinkAggregatorV3Address",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "symbol",
                "type": "string"
              },
              {
                "internalType": "uint8",
                "name": "decimals",
                "type": "uint8"
              }
            ],
            "internalType": "struct StructSupportedToken",
            "name": "nativeToken",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "isNative",
                "type": "bool"
              },
              {
                "internalType": "address",
                "name": "contractAddress",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "chainLinkAggregatorV3Address",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "symbol",
                "type": "string"
              },
              {
                "internalType": "uint8",
                "name": "decimals",
                "type": "uint8"
              }
            ],
            "internalType": "struct StructSupportedToken",
            "name": "projectToken",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "isNative",
                "type": "bool"
              },
              {
                "internalType": "address",
                "name": "contractAddress",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "chainLinkAggregatorV3Address",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "symbol",
                "type": "string"
              },
              {
                "internalType": "uint8",
                "name": "decimals",
                "type": "uint8"
              }
            ],
            "internalType": "struct StructSupportedToken",
            "name": "stableToken",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "isNative",
                "type": "bool"
              },
              {
                "internalType": "address",
                "name": "contractAddress",
                "type": "address"
              },
              {
                "internalType": "address",
                "name": "chainLinkAggregatorV3Address",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "symbol",
                "type": "string"
              },
              {
                "internalType": "uint8",
                "name": "decimals",
                "type": "uint8"
              }
            ],
            "internalType": "struct StructSupportedToken[]",
            "name": "supportedTokensArray",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "tokenValue",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructTokenWithValue[]",
            "name": "tokensCollected",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "tokenValue",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructTokenWithValue[]",
            "name": "incomeDistributedInTokens",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "planId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "per",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "duration",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "maxLimitMul",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructInvestmentPlan[]",
            "name": "investmentPlansArray",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "division",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructPerAndDiv",
                "name": "createLiquidityPer",
                "type": "tuple"
              },
              {
                "internalType": "uint256",
                "name": "initialPriceInUSD",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructCreateLiquidity",
            "name": "createLiquidity",
            "type": "tuple"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "division",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructPerAndDiv",
                "name": "rewardClaimFees",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "division",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructPerAndDiv",
                "name": "preUnStakeFees",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "division",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructPerAndDiv",
                "name": "providerFees",
                "type": "tuple"
              }
            ],
            "internalType": "struct StructFees",
            "name": "fees",
            "type": "tuple"
          }
        ],
        "internalType": "struct StructReturnsAnalyticsAndDefaults",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "planId_",
        "type": "uint256"
      }
    ],
    "name": "getInvestmentPlanById",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "planId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "per",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "duration",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "maxLimitMul",
            "type": "uint256"
          }
        ],
        "internalType": "struct StructInvestmentPlan",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenAddress_",
        "type": "address"
      }
    ],
    "name": "getSupportedToken",
    "outputs": [
      {
        "components": [
          {
            "internalType": "bool",
            "name": "isActive",
            "type": "bool"
          },
          {
            "internalType": "bool",
            "name": "isNative",
            "type": "bool"
          },
          {
            "internalType": "address",
            "name": "contractAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "chainLinkAggregatorV3Address",
            "type": "address"
          },
          {
            "internalType": "string",
            "name": "name",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "symbol",
            "type": "string"
          },
          {
            "internalType": "uint8",
            "name": "decimals",
            "type": "uint8"
          }
        ],
        "internalType": "struct StructSupportedToken",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "userAddress_",
        "type": "address"
      }
    ],
    "name": "getUserAccount",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "userAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "referrer",
            "type": "address"
          },
          {
            "internalType": "address[]",
            "name": "referees",
            "type": "address[]"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "user",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "level",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructTeam[]",
            "name": "teams",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "selfBusiness",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "directBusiness",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "teamBusiness",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructBusiness",
            "name": "business",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "referral",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bot",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "staking",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "community",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "bonanza",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "target",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "others",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructIncome",
            "name": "incomes",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "valueInUSD",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "startTime",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "pendingReward",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "rewardClaimed",
                "type": "uint256"
              },
              {
                "internalType": "bool",
                "name": "isActive",
                "type": "bool"
              },
              {
                "internalType": "address",
                "name": "owner",
                "type": "address"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "planId",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "per",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "duration",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "maxLimitMul",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct StructInvestmentPlan",
                "name": "investmentPlan",
                "type": "tuple"
              }
            ],
            "internalType": "struct StructStaking[]",
            "name": "stakings",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "tokenValue",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructTokenWithValue[]",
            "name": "investedWithTokens",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "internalType": "address",
                "name": "tokenAddress",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "tokenValue",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructTokenWithValue[]",
            "name": "incomeReceivedInTokens",
            "type": "tuple[]"
          }
        ],
        "internalType": "struct StructUserAccountReturn",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getVariablesContract",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "planId",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "per",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "duration",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "maxLimitMul",
            "type": "uint256"
          }
        ],
        "internalType": "struct StructInvestmentPlan[]",
        "name": "investmentPlans_",
        "type": "tuple[]"
      },
      {
        "components": [
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "per",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "division",
                "type": "uint256"
              }
            ],
            "internalType": "struct StructPerAndDiv",
            "name": "referralRate",
            "type": "tuple"
          },
          {
            "internalType": "uint256",
            "name": "levelCondition",
            "type": "uint256"
          }
        ],
        "internalType": "struct StructReferralRates[]",
        "name": "referralStruct_",
        "type": "tuple[]"
      },
      {
        "components": [
          {
            "internalType": "bool",
            "name": "isActive",
            "type": "bool"
          },
          {
            "internalType": "bool",
            "name": "isNative",
            "type": "bool"
          },
          {
            "internalType": "address",
            "name": "contractAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "chainLinkAggregatorV3Address",
            "type": "address"
          },
          {
            "internalType": "string",
            "name": "name",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "symbol",
            "type": "string"
          },
          {
            "internalType": "uint8",
            "name": "decimals",
            "type": "uint8"
          }
        ],
        "internalType": "struct StructSupportedToken",
        "name": "nativeToken_",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "bool",
            "name": "isActive",
            "type": "bool"
          },
          {
            "internalType": "bool",
            "name": "isNative",
            "type": "bool"
          },
          {
            "internalType": "address",
            "name": "contractAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "chainLinkAggregatorV3Address",
            "type": "address"
          },
          {
            "internalType": "string",
            "name": "name",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "symbol",
            "type": "string"
          },
          {
            "internalType": "uint8",
            "name": "decimals",
            "type": "uint8"
          }
        ],
        "internalType": "struct StructSupportedToken",
        "name": "projectToken_",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "bool",
            "name": "isActive",
            "type": "bool"
          },
          {
            "internalType": "bool",
            "name": "isNative",
            "type": "bool"
          },
          {
            "internalType": "address",
            "name": "contractAddress",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "chainLinkAggregatorV3Address",
            "type": "address"
          },
          {
            "internalType": "string",
            "name": "name",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "symbol",
            "type": "string"
          },
          {
            "internalType": "uint8",
            "name": "decimals",
            "type": "uint8"
          }
        ],
        "internalType": "struct StructSupportedToken",
        "name": "stableToken_",
        "type": "tuple"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "pendingOwner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "proxiableUUID",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user_",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "referrer_",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "tokenAddress_",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "valueInWei_",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "planId_",
        "type": "uint256"
      }
    ],
    "name": "register",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "variablesContractAddress_",
        "type": "address"
      }
    ],
    "name": "setVariablesContract",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newImplementation",
        "type": "address"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      }
    ],
    "name": "upgradeToAndCall",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "stateMutability": "payable",
    "type": "receive"
  }
] as const;
        export const IfasstUpgradeableDeploymentDetails: ContractDeploymentObjectsBlockchain = {
        [137]: {
            proxyAddress: '0xF1e0BC565AcACAd44B543B933C9F145a5211CDF4',
            implementationAddress: '0x119ECd337aBeb6C01EA9672aC4F1F0fd92b95AfD',
            contractName: 'IfasstUpgradeable',
            chainId: 137,
            deployer: '0xfbC93296d9D8BE15b9a98cf3eDe8BC780823A499',
            abi: IfasstUpgradeableABI
        }
}
    
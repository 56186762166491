import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, HStack, VStack, Heading, Tag } from '@chakra-ui/react';

interface CountdownProps {
    endTime: number; // UNIX timestamp in seconds
}

interface TimeLeft {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const CountDownHolder = ({ time, text }: { time: number, text: string }) => {
    return (
        <Tag boxSize={12} flexDir="column" borderWidth="thin" p={2} borderRadius="xl">
            <Heading size="md">{time.toString().padStart(2, '0')}</Heading>
            <Text fontSize="x-small">{text}</Text>
        </Tag>
    );
};

const CounterComponent: React.FC<CountdownProps> = ({ endTime }) => {
    const calculateTimeLeft = (): TimeLeft => {
        const difference = endTime * 1000 - new Date().getTime(); // Convert endTime to milliseconds
        let timeLeft: TimeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Clean up on component unmount
    }, [endTime]);

    return (
        <HStack>
            <CountDownHolder time={timeLeft.days} text='Days' />
            <CountDownHolder time={timeLeft.hours} text='Hours' />
            <CountDownHolder time={timeLeft.minutes} text='Minutes' />
            <CountDownHolder time={timeLeft.seconds} text='Seconds' />
        </HStack>
    );
};

export default CounterComponent;

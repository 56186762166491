import React, { ReactNode, useEffect } from "react";
import {
  Avatar,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Spacer,
  Spinner,
  Tag,
  Text,
  useClipboard,
  useColorModeValue,
  useToast,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { FiArrowDownLeft, FiArrowUpRight } from "react-icons/fi";
import { HiMiniArrowUpTray } from "react-icons/hi2";
import {
  TbArrowRotaryStraight,
  TbArrowsExchange2,
  TbArrowsShuffle,
  TbArrowUpToArc,
} from "react-icons/tb";
import { useAccount } from "wagmi";
import { WcTransactionType } from "../../../constants/types";
import { useUserAllTransactions } from "../../../hooks/wcHooks";
import { shortenAddress } from "../../../utils/web3Functions";
import { BlockchainApiTransactionsResponse } from "@web3modal/core";
import { NumberUtil } from "@web3modal/common";
import { format, parseISO } from "date-fns";
import { convertIsoTimeToTime } from "../../../utils/utils";
import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";

// Define interfaces
interface Transaction {
  metadata: {
    operationType: WcTransactionType;
    minedAt: string;
    sentTo?: string;
    application?: {
      name?: string;
      iconUrl?: string;
    };
    chain: `${string}:${string}`;
    hash: string;
  };
  transfers?: {
    direction: "in" | "out";
    quantity: {
      numeric: number;
    };
    fungible_info: {
      symbol: string;
    };
  }[];
}

type GroupedTransactions = {
  [date: string]: Transaction[];
};

export const Transactions = () => {
  const { address } = useAccount();
  const userTransactions = useUserAllTransactions(address);
  const { hasCopied, onCopy, setValue, value } = useClipboard("");
  const toast = useToast();

  const InLoadingState = ({
    isLoading,
    children,
  }: {
    isLoading: boolean;
    children: ReactNode;
  }) => {
    return <>{isLoading ? <Spinner /> : children}</>;
  };

  const NoTransactionsComponent = ({
    transactions,
    children,
  }: {
    transactions: BlockchainApiTransactionsResponse | null;
    children: ReactNode;
  }) => {
    return (
      <>
        {transactions?.data && transactions?.data?.length > 0 ? (
          children
        ) : (
          <Heading textAlign="center" color="red.400">
            You have no transactions
          </Heading>
        )}
      </>
    );
  };

  useEffect(() => {
    hasCopied &&
      toast({
        title: "Copied successfully.",
        description: `${value}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  }, [value, hasCopied, toast]);

  console.log("userTransactions", userTransactions);

  const transactionObjectExt = {
    ["send"]: {
      icon: FiArrowUpRight,
      colorScheme: "red",
      fromTo: "To",
    },
    ["receive"]: {
      icon: FiArrowDownLeft,
      colorScheme: "green",
      fromTo: "From",
    },
    ["approve"]: {
      icon: TbArrowUpToArc,
      colorScheme: "orange",
      fromTo: "Approved Contract",
    },
    ["trade"]: {
      icon: TbArrowsExchange2,
      colorScheme: "twitter",
      fromTo: "From",
    },
    ["execute"]: {
      icon: TbArrowRotaryStraight,
      colorScheme: "red",
      fromTo: "Executed",
    },
    ["deploy"]: {
      icon: TbArrowsShuffle,
      colorScheme: "red",
      fromTo: "Deployed",
    },
    ["deposit"]: {
      icon: HiMiniArrowUpTray,
      colorScheme: "green",
      fromTo: "Deposited To",
    },
  };

  const bgColor = useColorModeValue("white", "blackAlpha.700");

  // Group transactions by date
  // @ts-ignore
  const groupedTransactions: GroupedTransactions =
    userTransactions?.transactions?.data?.reduce(
      // @ts-ignore
      (acc: GroupedTransactions, transaction: Transaction) => {
        const date = format(
          parseISO(transaction.metadata.minedAt),
          "yyyy-MM-dd"
        );
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(transaction);
        return acc;
      },
      {} as GroupedTransactions
    );

  return (
    <VStack w="full" spacing={10}>
      <HStack>
        <Heading>Transactions</Heading>
        <Icon as={FaArrowRightArrowLeft} boxSize={10}></Icon>
      </HStack>
      <VStack w="full">
        <InLoadingState isLoading={userTransactions?.loading}>
          <NoTransactionsComponent
            transactions={userTransactions?.transactions}
          >
            {groupedTransactions &&
              Object.keys(groupedTransactions).map((date) => (
                <React.Fragment key={date}>
                  <Heading size="md" w="full">
                    {date}
                  </Heading>
                  {groupedTransactions[date].map((object, key) => {
                    const transactionType = object?.metadata
                      ?.operationType as WcTransactionType;
                    const objectExtention =
                      transactionObjectExt[transactionType] || {};
                    return (
                      <VStack
                        w="full"
                        borderWidth={"thin"}
                        p={3}
                        borderRadius="3xl"
                        bgColor={bgColor}
                      >
                        <Wrap key={key} w="full">
                          <Tag
                            boxSize={10}
                            borderRadius={"xl"}
                            colorScheme={objectExtention?.colorScheme}
                          >
                            <Icon
                              boxSize={6}
                              as={objectExtention?.icon}
                              color={objectExtention?.colorScheme}
                            ></Icon>
                          </Tag>
                          <Flex direction={"column"} gap={2}>
                            <HStack>
                              <Heading textTransform="capitalize" size="md">
                                {object?.metadata?.operationType}
                              </Heading>
                              <Tag
                                fontSize="xx-small"
                                size="sm"
                                colorScheme="green"
                              >
                                {convertIsoTimeToTime(
                                  object?.metadata?.minedAt
                                )}
                              </Tag>
                            </HStack>
                            {object?.metadata?.sentTo && (
                              <HStack>
                                <Text>{objectExtention?.fromTo}:</Text>
                                <Tag fontSize="xs">
                                  {shortenAddress(
                                    object?.metadata?.sentTo as `0x${string}`
                                  )}
                                </Tag>
                              </HStack>
                            )}
                            {object?.metadata?.application?.name && (
                              <HStack>
                                <Avatar
                                  src={object?.metadata?.application?.iconUrl}
                                  size="xs"
                                  borderWidth={"thin"}
                                ></Avatar>
                                <Text>
                                  {object?.metadata?.application?.name}
                                </Text>
                              </HStack>
                            )}
                          </Flex>
                          <Spacer />
                          {object?.transfers &&
                            object?.transfers?.length > 0 && (
                              <Tag
                                borderRadius={"3xl"}
                                p={3}
                                colorScheme="twitter"
                                w={["full", "full", "fit-content"]}
                                justifyContent="center"
                              >
                                <VStack>
                                  {object?.transfers?.map(
                                    (transferObject, key) => {
                                      return (
                                        <Heading
                                          size="xs"
                                          key={key}
                                          color={
                                            transferObject?.direction === "in"
                                              ? "green"
                                              : "red.400"
                                          }
                                          fontWeight={500}
                                        >
                                          {transferObject?.direction === "in"
                                            ? "+"
                                            : "-"}{" "}
                                          {NumberUtil?.formatNumberToLocalString(
                                            transferObject?.quantity?.numeric,
                                            3
                                          )}{" "}
                                          {
                                            transferObject?.fungible_info
                                              ?.symbol
                                          }
                                        </Heading>
                                      );
                                    }
                                  )}
                                </VStack>
                              </Tag>
                            )}
                        </Wrap>
                        <Divider />
                        <Wrap>
                          <Button
                            size="xs"
                            rightIcon={<CopyIcon />}
                            onClick={() => {
                              setValue(
                                `Transaction Hash: ${shortenAddress(
                                  object?.metadata?.hash as `0x${string}`
                                )}`
                              );
                              onCopy();
                            }}
                          >
                            Copy Transaction Hash
                          </Button>
                          <Button
                            size="xs"
                            rightIcon={<ExternalLinkIcon />}
                            as={Link}
                            href=""
                          >
                            View in explorer
                          </Button>
                        </Wrap>
                      </VStack>
                    );
                  })}
                </React.Fragment>
              ))}
          </NoTransactionsComponent>
        </InLoadingState>
      </VStack>
    </VStack>
  );
};
